import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../../../enums';

const Component = () => import('./MealPlans.vue');

export default {
  path: buildChildURL(SitesRoutePathNames.MEAL_PLANS),
  name: SitesRouteNames.SITE_MEAL_PLANS,
  component: Component,
} as RouteRecordRaw;
