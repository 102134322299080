<template>
  <Accordion
    v-bind="$attrs"
    v-model:activeIndex="localActiveIndex"
    @update:active-index="onTabChange"
    class="page-accordion"
  >
    <component v-for="(tab, index) in tabs" :key="index" :is="AccordionTab">
      <template #headericon="{ isTabActive }">
        <template v-if="!loading">
          <span v-if="isTabActive" class="material-symbols-rounded mr-2" aria-hidden="true"
            >expand_more</span
          >
          <span v-else class="material-symbols-rounded mr-2" aria-hidden="true">chevron_right</span>
        </template>

        <template v-else>
          <Skeleton width="18px" class="mr-2" />
        </template>
      </template>

      <template #header>
        <div class="flex justify-content-between align-items-center w-full">
          <div class="flex flex-column">
            <div class="flex">
              <h2 class="font-semibold" v-if="!loading">{{ tab.header }}</h2>
              <Skeleton width="10rem" v-else />
            </div>

            <div class="flex" v-if="tab?.headerSubtitle">
              <span class="header-subtitle" v-if="!loading"> {{ tab?.headerSubtitle }}</span>
              <Skeleton width="4rem" class="mt-1" v-else />
            </div>
          </div>

          <div class="flex align-items-center" v-if="tab?.actionsComponent">
            <div class="flex align-items-center">
              <component :is="tab.actionsComponent" v-bind="tab.actionsProps" v-if="!loading" />
              <Skeleton width="4rem" height="2rem" v-else />
            </div>
          </div>
        </div>
      </template>

      <div class="w-full px-4">
        <div v-if="!loading">
          <component :is="tab.component" v-bind="tab.props" />
        </div>

        <div class="flex w-full" v-else>
          <ContentSkeleton />
        </div>
      </div>
    </component>
  </Accordion>
</template>

<script setup lang="ts">
import { ref, watch, type Component, type PropType } from 'vue';
import { Accordion, AccordionTab, Skeleton } from '../../../core';
import ContentSkeleton from './components/content-skeleton/ContentSkeleton.vue';

// Define emits
const emit = defineEmits(['update:activeIndex']);

// Define options
defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  activeIndex: {
    type: [Number, Array] as PropType<number | number[]>,
  },
  tabs: {
    type: Array as () => {
      header: string;
      component: Component;
      headerSubtitle?: string;
      props?: object;
      actionsComponent?: Component;
      actionsProps?: object;
    }[],
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

// Local state for activeIndex
const localActiveIndex = ref<number | number[]>(props.activeIndex);

// Update local value on tab change
const onTabChange = (index: number | number[]): void => {
  localActiveIndex.value = index;
};

// Watch for changes in the prop and update the local value
watch(
  () => props.activeIndex,
  (newVal) => {
    localActiveIndex.value = newVal;
  },
);

// Emit changes back to the parent when activeIndex changes
watch(localActiveIndex, (newVal) => {
  emit('update:activeIndex', newVal);
});
</script>

<style lang="scss" scoped>
@import '@centric-os/styles';

.header-title {
  color: $h2HeaderColor;
}

.header-subtitle {
  @extend .text-500, .font-normal, .text-xs, .mt-1;
}
</style>
