export enum FeatureFlags {
  MENU_SIMPLIFIED_VIEW = 'CDL-17142-Menu-Simplified-View',
  MENU_SIDEBAR_UI_UPDATES = 'CDL-19152-menu-sidebar-updates',
  BRAND_FILTER = 'CDL-9348-filter-by-brand',
  CANADA_REFUNDS_ENABLED = 'CDL-10248-allow-canada-refunds',
  HIDE_DELETE_FOR_MENU_ENTITIES = 'Hide-Delete-For-Menu-Entities',
  FP_PROMO = 'FreedomPay-Promo',
  GENERATIVE_AI = 'generative-ai',
  HIDE_IMPORT_EXPORT_ITEMS = 'Hide-Import-Export-Items',
  HIDE_SALES_CHANNEL = 'Hide-Sales-Channel',
  HIDE_NEXTEP_GAPS = `hide-nextep-gaps`,
  HIDE_DUPLICATE_MODIFIER_GROUP = `Hide-Duplicate-Modifier-Group`,
  MULTIPLE_BRANDS_TO_STATION = 'Multiple-Menu-Brands-to-Station',
  AUTOLOAD_LOOKER = 'CDL-16740-autoload-looker',
  HIDE_MENU_WORKS = 'hide_menu_works',
  HIDE_DEVICE_MAPPING = 'Hide-Device-Mapping',
  TAX_TAG_CODE = 'tax_tag_code',
  CENTRIC_MEALPLAN_REFUNDS = 'CDL-18110-allow-centric-mealplan-refunds',
  RECEIPT_KDS_NAME = 'RECEIPT-KDS-NAME',
  CROSS_SELL_MENU = 'Cross-Sell-Menu',
  JWO_MENU_INTEGRATION = 'JWO-Menu-Integration',
  SYSTEM_365 = 'System-365',
  KDS_TEMPORARY_OUT_OF_STOCK = 'KDS-Temporary-Out-of-Stock',
  SCANNER = 'CDL-18134-scanner',
  CD_MOBILE_ORDER_TYPE = 'CD_MOBILE_ORDER_TYPE',
  ITEM_AND_MODIFIER_BULK_DELETE = 'CDL-18713-Item-Modifier-Bulk-Delete',
  NEXTEP_STATION_ALLOW_DISABLE_MENU = 'Nextep-Station-Allow-Disable-Menu',
  PICKUP_5MIN_TRANSITION = 'PICKUP-5MIN-TRANSITION',
  LOOKER_VANITY_URL = 'CDL-18900-new-looker-vanity-url',
  CPG_ITEM_UPLOAD = 'CDL-18946-CPG-upload',
  EXPORT_MENU_GROUP = 'CDL-19927-Export-Group',
  SHOW_SITE_KDS = 'SHOW_SITE_KDS',
  MENUWORKS_V2 = 'CDL-19289-Menuworks-v2',
  AUDIT_TABLE_V2 = 'CDL-20081-audit-table-v2',
  MANAGE_BUSINESS_UNIT = 'MANAGE_BUSINESS_UNIT',
  MANAGE_SITE_MEAL_PLANS = 'MANAGE_SITE_MEAL_PLANS',
}

export enum AP3FeatureFlags {
  USER_MANAGEMENT = 'AP3-USER-MANAGEMENT',
  HIDE_LMG_SITE_LINK = 'Hide-LMG-Site-Link',
}
