import type { RouteRecordRaw } from 'vue-router';

import sitesListRoute from './sites-list';
import singleSiteRoute from './site-details';
import stationSchedule from './station-schedule';
import businessUnitUpdate from './business-unit-update';

export default [
  sitesListRoute,
  singleSiteRoute,
  stationSchedule,
  businessUnitUpdate,
] as RouteRecordRaw[];

export * from './stores';
