
      import process from 'process';
      import EventEmitter from 'events';
      import { Buffer } from 'buffer';

      const isIDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      const addMaximumScaleToMetaViewport = () => {
        const el = document.querySelector('meta[name=viewport]');
        if (!el) {
          return;
        }
        // Get the current content of the viewport meta tag
        let content = el.getAttribute('content') || '';

        // Add maximum-scale=1.0 to the content
        if (content) {
          content += ', maximum-scale=1.0';
        } else {
          content = 'maximum-scale=1.0';
        }
        el.setAttribute('content', content);
      };
      if (isIDevice) {
        addMaximumScaleToMetaViewport();
      }

      window.Buffer = Buffer;
      window.process = process;
      window.EventEmitter = EventEmitter;

      // Function to dynamically load zendesk script based on the condition
      function loadZendeskScript(src, key, id, callback) {
        const script = document.createElement('script');
        // Append the key as a query parameter to the src URL
        script.src = `${src}?key=${key}`;
        script.async = true; // Load script asynchronously
        script.id = id; // Set the ID for the script tag
        script.onload = callback; // Call the callback function after the script has loaded
        document.head.appendChild(script); // Append the script tag to <head>
      }

      const stage = import.meta.env.VITE_STAGE;
      if (stage === 'v1') {
        loadZendeskScript(
          'https://static.zdassets.com/ekr/snippet.js',
          '7153044c-8cc4-450d-879e-523649b1841c',
          'ze-snippet',
        );
      } else {
        loadZendeskScript(
          'https://static.zdassets.com/ekr/snippet.js',
          'a60e3f73-cd34-434b-bc6b-ac88c1f0743e',
          'ze-snippet',
        );
      }

      // Function to dynamically load dynatrace script based on the condition
      function loadDynaScript(src, id, callback) {
        const script = document.createElement('script');

        // Append the src URL
        script.src = `${src}`;
        script.async = true; // Load script asynchronously
        script.id = id; // Set the ID for the script tag
        script.setAttribute('crossorigin', 'anonymous'); // Explicitly set crossorigin using setAttribute
        script.onload = callback; // Call the callback function after the script has loaded
        document.head.appendChild(script); // Append the script tag to <head>
      }

      const dynatraceSrc = import.meta.env.VITE_DYNATRACE;
      if (stage === 'v1') {
        // loadDynaScript(dynatraceSrc, 'dynatrace-v1'); // Commented till we fix CORS issue
      } else if (stage === 'staging') {
        // loadDynaScript(dynatraceSrc, 'dynatrace-staging'); // Commented till we fix CORS issue
      } else if (stage === 'dev') {
        loadDynaScript(dynatraceSrc, 'dynatrace-dev'); // Dev for test CORS issue
      }

      /**
       * Manually create and load appcues script to skip integrity hash step.
       * The alternative is to add an integrity hash step, which requires self hosting
       * of the appcues script. This is not recommended.
       */
      const script = document.createElement('script');
      script.src = 'https://fast.appcues.com/204646.js';
      script.async = true;
      script.id = 'appcues';

      document.head.appendChild(script);
    